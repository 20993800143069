import React, { useRef, useEffect } from 'react'
import { motion, useMotionValue } from 'motion/react'
import { cn } from '@/utils/ui'

export type InfiniteDirection = 'left' | 'right' | 'up' | 'down'

// Tailwind spacing scale in pixels
const TAILWIND_SPACING = {
  0: '0px',
  0.5: '0.125rem',
  1: '0.25rem',
  1.5: '0.375rem',
  2: '0.5rem',
  2.5: '0.625rem',
  3: '0.75rem',
  3.5: '0.875rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
  11: '2.75rem',
  12: '3rem',
  14: '3.5rem',
  16: '4rem',
  20: '5rem',
  24: '6rem',
  28: '7rem',
  32: '8rem',
  36: '9rem',
  40: '10rem',
  44: '11rem',
  48: '12rem',
  52: '13rem',
  56: '14rem',
  60: '15rem',
  64: '16rem',
  72: '18rem',
  80: '20rem',
  96: '24rem',
} as const

type TailwindSpacing = keyof typeof TAILWIND_SPACING

export type MotionInfiniteProps = {
  children: React.ReactNode
  className?: string
  /**
   * Direction of infinite scroll
   * @default 'left'
   */
  direction?: InfiniteDirection
  /**
   * Speed of scroll in pixels per second
   * @default 50
   */
  speed?: number
  /**
   * Whether to pause on hover
   * @default true
   */
  pauseOnHover?: boolean
  /**
   * Gap between repeated elements using Tailwind spacing scale
   * @default 2
   */
  gap?: TailwindSpacing
  /**
   * Number of times to repeat the content
   * @default 2
   */
  repeat?: number
  /**
   * Whether to disable the effect
   * @default false
   */
  disabled?: boolean
}

// Convert rem string to pixels
const remToPx = (rem: string): number => {
  return parseFloat(rem) * 16
}

export const MotionInfinite: React.FC<MotionInfiniteProps> = ({
  children,
  className,
  direction = 'left',
  speed = 50,
  pauseOnHover = false,
  gap = 2,
  repeat = 2,
  disabled = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [contentWidth, setContentWidth] = React.useState(0)
  const [contentHeight, setContentHeight] = React.useState(0)
  const [isPaused, setIsPaused] = React.useState(false)

  // Store animation state
  const animationState = useRef({
    startTime: 0,
    lastTimestamp: 0,
    pausedOffset: 0,
    totalPausedTime: 0,
  })

  // Convert gap to pixels
  const gapPx = React.useMemo(() => {
    const remValue = TAILWIND_SPACING[gap]
    return remToPx(remValue)
  }, [gap])

  // Motion value for position
  const position = useMotionValue(0)

  // Measure content size
  useEffect(() => {
    if (!containerRef.current) return

    const container = containerRef.current
    const firstChild = container.firstElementChild as HTMLElement
    if (!firstChild) return

    const updateSize = () => {
      setContentWidth(firstChild.offsetWidth + gapPx)
      setContentHeight(firstChild.offsetHeight + gapPx)
    }

    updateSize()

    const resizeObserver = new ResizeObserver(() => {
      updateSize()
    })
    resizeObserver.observe(firstChild)

    return () => {
      resizeObserver.disconnect()
    }
  }, [gapPx])

  // Handle pause and resume
  useEffect(() => {
    if (isPaused) {
      animationState.current.lastTimestamp = performance.now()
    } else {
      animationState.current.totalPausedTime +=
        performance.now() - animationState.current.lastTimestamp
    }
  }, [isPaused])

  // Animate position with cancelAnimationFrame cleanup
  useEffect(() => {
    if (disabled || !contentWidth || !contentHeight) return

    const totalDistance =
      direction === 'left' || direction === 'right' ? contentWidth : contentHeight
    const multiplier = direction === 'right' || direction === 'down' ? 1 : -1

    let animationFrameId: number

    const animate = (currentTime: number) => {
      if (isPaused) {
        animationFrameId = requestAnimationFrame(animate)
        return
      }

      if (animationState.current.startTime === 0) {
        animationState.current.startTime = currentTime
      }

      const effectiveTime =
        currentTime - animationState.current.startTime - animationState.current.totalPausedTime
      const distance = (effectiveTime * speed) / 1000
      const offset = (distance % totalDistance) * multiplier
      position.set(offset)

      animationFrameId = requestAnimationFrame(animate)
    }

    animationFrameId = requestAnimationFrame(animate)

    return () => {
      cancelAnimationFrame(animationFrameId)
    }
  }, [disabled, contentWidth, contentHeight, speed, direction, position, isPaused])

  if (disabled) return <>{children}</>

  const isHorizontal = direction === 'left' || direction === 'right'
  const contentArray = Array(repeat).fill(children)

  return (
    <div
      className={cn('relative overflow-hidden', className)}
      onMouseEnter={() => pauseOnHover && setIsPaused(true)}
      onMouseLeave={() => pauseOnHover && setIsPaused(false)}
    >
      <motion.div
        ref={containerRef}
        className={cn('flex', isHorizontal ? 'flex-row' : 'flex-col')}
        style={{
          x: isHorizontal ? position : 0,
          y: !isHorizontal ? position : 0,
          gap,
        }}
      >
        {contentArray.map((content, index) => (
          <div key={index} className={cn('shrink-0', isHorizontal ? 'w-max' : 'h-max')}>
            {content}
          </div>
        ))}
      </motion.div>
    </div>
  )
}
