import React, { useState, useCallback, useMemo } from 'react'
import { motion, AnimatePresence, PanInfo } from 'motion/react'
import { cn } from '@/utils/ui'

type BreakpointConfig = {
  [key: number]: {
    slidesPerView: number
    gap?: number
  }
}

export type MotionSliderProps = {
  children: React.ReactNode[]
  className?: string
  /**
   * Gap between slides in pixels
   * @default 24
   */
  gap?: number
  /**
   * Whether to show navigation dots
   * @default true
   */
  showDots?: boolean
  /**
   * Whether to show navigation arrows
   * @default true
   */
  showArrows?: boolean
  /**
   * Whether to enable infinite sliding
   * @default true
   */
  infinite?: boolean
  /**
   * Whether to enable drag to slide
   * @default true
   */
  draggable?: boolean
  /**
   * Minimum drag distance to trigger slide
   * @default 50
   */
  dragThreshold?: number
  /**
   * Duration of slide transitions in seconds
   * @default 0.5
   */
  duration?: number
  /**
   * Number of slides to show at once
   * @default 1
   */
  slidesPerView?: number
  /**
   * Responsive breakpoints configuration
   * Example: { 768: { slidesPerView: 2 }, 1024: { slidesPerView: 3, gap: 32 } }
   */
  breakpoints?: BreakpointConfig
}

export const MotionSlider: React.FC<MotionSliderProps> = ({
  children,
  className,
  gap = 24,
  showDots = true,
  showArrows = true,
  infinite = true,
  draggable = true,
  dragThreshold = 50,
  duration = 0.5,
  slidesPerView = 1,
  breakpoints,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [windowWidth, setWindowWidth] = useState(0)

  // Update window width on client-side only after initial render
  React.useEffect(() => {
    if (typeof window === 'undefined') return

    // Set the initial window width
    setWindowWidth(window.innerWidth)

    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Calculate current configuration based on breakpoints and window width
  const currentConfig = useMemo(() => {
    if (!breakpoints) {
      return { slidesPerView, gap }
    }

    // Get breakpoint settings
    const breakpointValues = Object.keys(breakpoints)
      .map(Number)
      .sort((a, b) => b - a)

    for (const breakpoint of breakpointValues) {
      if (windowWidth >= breakpoint) {
        const config = breakpoints[breakpoint]
        return {
          slidesPerView: config?.slidesPerView ?? slidesPerView,
          gap: config?.gap ?? gap,
        }
      }
    }

    return { slidesPerView, gap }
  }, [breakpoints, slidesPerView, gap, windowWidth])

  const slideCount = React.Children.count(children)
  const slidesToShow = currentConfig.slidesPerView
  const maxIndex = Math.max(0, slideCount - slidesToShow)

  const handleNext = useCallback(() => {
    setCurrentIndex((prev) =>
      infinite ? (prev + 1) % (maxIndex + 1) : Math.min(prev + 1, maxIndex),
    )
  }, [infinite, maxIndex])

  const handlePrevious = useCallback(() => {
    setCurrentIndex((prev) =>
      infinite ? (prev - 1 + maxIndex + 1) % (maxIndex + 1) : Math.max(prev - 1, 0),
    )
  }, [infinite, maxIndex])

  const handleDragEnd = useCallback(
    (info: PanInfo) => {
      const dragDistance = info.offset.x
      if (Math.abs(dragDistance) > dragThreshold) {
        if (dragDistance > 0) {
          handlePrevious()
        } else {
          handleNext()
        }
      }
    },
    [dragThreshold, handleNext, handlePrevious],
  )

  // Create an array of visible slides
  const visibleSlides = useMemo(() => {
    const visibleIndices = Array.from({ length: slidesToShow }, (_, i) => {
      const idx = currentIndex + i
      return infinite ? idx % slideCount : Math.min(idx, slideCount - 1)
    })

    return visibleIndices.map((index) => children[index])
  }, [children, currentIndex, infinite, slideCount, slidesToShow])

  return (
    <div className={cn('relative overflow-hidden', className)}>
      <motion.div
        className="flex"
        style={{ gap: currentConfig.gap }}
        drag={draggable ? 'x' : false}
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={0.5}
        onDragEnd={(event, info) => handleDragEnd(info)}
      >
        <AnimatePresence mode="wait" initial={false}>
          <motion.div
            key={currentIndex}
            className="flex w-full"
            style={{ gap: currentConfig.gap }}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{
              duration,
              ease: [0.22, 1, 0.36, 1],
            }}
          >
            {visibleSlides.map((slide, index) => (
              <div
                key={`slide-${currentIndex}-${index}`}
                className={`flex-shrink-0`}
                style={{
                  width:
                    slidesToShow === 1
                      ? '100%'
                      : `calc((100% - ((${slidesToShow} - 1) * ${currentConfig.gap}px)) / ${slidesToShow})`,
                }}
              >
                {slide}
              </div>
            ))}
          </motion.div>
        </AnimatePresence>
      </motion.div>

      {showArrows && (
        <>
          <button
            onClick={handlePrevious}
            disabled={!infinite && currentIndex === 0}
            className={cn(
              'absolute top-1/2 left-4 z-10 -translate-y-1/2',
              'rounded-full bg-white/80 p-2 shadow-lg',
              'transition-colors hover:bg-white',
              'disabled:cursor-not-allowed disabled:opacity-50',
            )}
            aria-label="Previous slide"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            onClick={handleNext}
            disabled={!infinite && currentIndex >= maxIndex}
            className={cn(
              'absolute top-1/2 right-4 z-10 -translate-y-1/2',
              'rounded-full bg-white/80 p-2 shadow-lg',
              'transition-colors hover:bg-white',
              'disabled:cursor-not-allowed disabled:opacity-50',
            )}
            aria-label="Next slide"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </>
      )}

      {showDots && slideCount > slidesToShow && (
        <div className="absolute bottom-4 left-1/2 flex -translate-x-1/2 gap-2">
          {Array.from({ length: maxIndex + 1 }).map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={cn(
                'h-2 w-2 rounded-full transition-colors',
                index === currentIndex ? 'bg-white shadow-lg' : 'bg-white/50 hover:bg-white/75',
              )}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      )}
    </div>
  )
}
